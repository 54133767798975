export async function renderRoute() {
    let path = window.location.pathname.split('/');
    console.log('path1',path);
    
    // const js = await import(`/public/js${targetPath}.js`);
    // console.log(js);
    if(path[path.length-1] == '') {
        path = path.slice(0, -1);
    }
    console.log('path2',path);
    
    let targetFilePath = `/pages/${path.slice(1).join('/')}/index.html`;
    console.log('targetFilePath',targetFilePath);
    // Check existence of file
    let response = await fetch(targetFilePath);
    if (response.status === 404) {
        targetFilePath = `/pages/${path.slice(1).join('/')}.html`;
    }
    // Check existence of index in directory
    response = await fetch(targetFilePath);
    if (response.status === 404) {
        targetFilePath = `/pages/404.html`;
    }
    console.log(targetFilePath);
    const component = await (await fetch(targetFilePath + '?raw')).text();

        
    return component;
}